import TasqResponse from '@/interfaces/tasqs/TasqResponse';





const mapTasqResponse = (r): TasqResponse => {
  // const workflowDetails = t.WorkflowCondition
  //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null;
  
  const response = {
	id: r.PredictionID,
	workflowTasqId: r.WorkflowTaskID,
	validate: r.Validate,
	validateComment: r.ValidateComment,
	systems: r.Systems,
	systemsComment: r.SystemsComment,
	symptoms: r.Symptoms,
	symptomsComment: r.SymptomsComment,
	causes: r.Causes,
	causesComment: r.CausesComment,
	actions: r.Actions,
	actionsComment: r.ActionsComment,
	jsonVersion: r.ProceduresJSONVersion,
	stepOneCompleted: r.Validate && r.Validate != '',
	stepTwoCompleted: r.Systems && r.Systems.length > 0,
	stepThreeCompleted: r.Symptoms && r.Symptoms.length > 0,
	stepFourCompleted: r.Causes && r.Causes.length > 0,
	stepFiveCompleted: r.Actions && r.Actions.length > 0,

  };


//   // @ts-ignore
//   response.stepOneCompleted = getStepOneCompleted(response);
//   // @ts-ignore
//   response.stepTwoCompleted = getStepTwoCompleted(response);
//   // @ts-ignore
//   response.stepThreeCompleted = getStepThreeCompleted(response);
//   // @ts-ignore
//   response.stepFourCompleted = getStepFourCompleted(response);
//   // @ts-ignore
//   response.stepLabel = getStepLabel(response);
//   // @ts-ignore
//   response.dateAgoText = getDateAgoText(response).text;
//   // @ts-ignore
//   response.dateAgoValue = getDateAgoText(response).value;
//   // @ts-ignore



  // @ts-ignore
  return response;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapTasqResponse,
};
