import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getWellDataTimeRange from '@/graphql/cleanData/queries/getWellDataTimeRange.graphql';
import getReferenceTimeRange from '@/graphql/deferment/queries/getReferenceTimeRange.graphql';
import listNodeEventsV2 from '@/graphql/cleanData/queries/listNodeEventsV2.graphql';
import getProcedureResponsesByNodeId from '@/graphql/procedures/queries/getProcedureResponsesByNodeId.graphql';
import getWellCommentHistory from '@/graphql/workflow/queries/getWellCommentHistory.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import defermentApolloClient from '@/lib/appsync/deferment';
import proceduresApolloClient from '@/lib/appsync/procedures';
import workflowApolloClient from '@/lib/appsync/workflow';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqProductionDataChartModule',
  store,
})
class TasqProductionDataChartModule extends VuexModule {
  productionData: any = {
    dataset: [],
    time: [],
    loaded: false,
  };

  adminChartProductionData: any = {};

  productionDataDefermentLabeling: any = {
    dataset: [],
    time: [],
    loaded: false,
  };


  productionDataDict: any = {
    date: [],
    gas_rate: [],
    nodeid: '',
    oil_rate: [],
    water_rate: []
  }

  defermentType: string = '';

  wellHistory: any = [];


  @Mutation
  resetProductionDataDict(): void {
    this.productionDataDict = {
      date: [],
      gas_rate: [],
      nodeid: '',
      oil_rate: [],
      water_rate: []
  	};
  }


  @Mutation
  setAdminChartProductionData(data): void {
    this.adminChartProductionData = data;
    // console.log(this.adminChartProductionData)
  }


  @Mutation
  resetProductionData(): void {
    this.productionData = {
      data: [],
      time: [],
      loaded: false,
    };
    this.wellHistory = [];
  }


  @Mutation
  setDefermentType(deferment_type): void {
    this.defermentType = deferment_type;
  }


  @Mutation
  setProductionDataDict(data): void {
    this.productionDataDict = data;
  }


  @Mutation
  setProductionData(data): void {
    this.productionData = data;
  }

  @Mutation
  setProductionDataDefermentLabeling(data): void {
    this.productionDataDefermentLabeling = data;
  }
  


  @Mutation
  resetProductionDataDefermentLabeling(): void {
    this.productionDataDefermentLabeling = {
      dataset: [],
      time: [],
      loaded: false,
	  };
  }
  

  @Mutation
  setWellHistoryData(data): void {
    this.wellHistory = data;
  }


  



  @Action
  async queryProductionData(data: any = 14): Promise<{}> {
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName
    let isOfflinePreloading = false;
    let daysLength = 14;
    if(typeof data === 'object') {
      wellName = data.wellName;
      daysLength = data.daysLength;
      isOfflinePreloading = true;
    } else {
      daysLength = data
    }

    const fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack  }).startOf('day').toISO().slice(0, -5);
    const toTime = DateTime.utc().minus({days: tasqsListModule.fromNowBack}).endOf('day').toISO().slice(0, -5);
    if (!wellName) {
      return {};
    }
    
    const {
      data: {
        get_well_test_data_time_range_v2: {
          date: time = [],
          oil_rate: oilData = [],
          water_rate: waterData = [],
          gas_rate: gasData = [],
        },
      },
    } = await cleanDataApolloClient.query({
      query: getWellDataTimeRange,
      variables: {
        nodeid: wellName,
        from_date: fromTime,
        to_date: toTime,
        operator: getConfigEnv('OPERATOR_PASCALED'),
      },
    });
    const {
      data: {
        get_reference_prod_time_range: rawReferenceData,
      },
    } = await defermentApolloClient.query({
      query: getReferenceTimeRange,
      variables: {
        nodeid: wellName,
        operator: getConfigEnv('OPERATOR_PASCALED'),
        description: "gas_rate",
        from_time: fromTime,
        to_time: toTime,
      },
    });

    const {
      data: {
        get_reference_prod_time_range: rawReferenceDataOilRate,
      },
    } = await defermentApolloClient.query({
      query: getReferenceTimeRange,
      variables: {
        nodeid: wellName,
        operator: getConfigEnv('OPERATOR_PASCALED'),
        description: "oil_rate",
        from_time: fromTime,
        to_time: toTime,
      },
    });


    if(!isOfflinePreloading) {
      this.setDefermentType(rawReferenceData.deferment_type);
    }


    return {
      rawReferenceData: rawReferenceData,
      rawReferenceDataOilRate: rawReferenceDataOilRate,
      referenceDatasetOilRate: rawReferenceDataOilRate.dataset,
      referenceDataset: rawReferenceData.dataset,
      time: time,
      oilData: oilData,
      waterData: waterData,
      gasData: gasData
    };


  }





  @Action
  async getProductionDataDefermentLabeling(data: any = 14): Promise<void> {
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName

    let daysLength = 14;
    if(typeof data === 'object') {
      wellName = data.wellName;
      daysLength = data.daysLength;
      isOfflinePreloading = true;
    } else {
      daysLength = data
    }
    // daysLength = 100
    if (!wellName) {
      return;
    }
    let response: any = {};
    const result = await localForage.getItem(`getProductionDataDefermentLabeling-${wellName}`); 
    if (isOnlineApp) {
      response = await this.queryProductionData(daysLength);
      await localForage.setItem(`getProductionDataDefermentLabeling-${wellName}`, response);
    } else if (result) {
      response = result;
    }
	
    var rawReferenceData = response["rawReferenceData"];
    var rawReferenceDataOilRate = response["rawReferenceDataOilRate"];
    var time = response["time"];
    var gasData = response["gasData"];
    var oilData = response["oilData"];
    var waterData = response["waterData"];
    const referenceDataset = JSON.parse(response["referenceDataset"]);
    const referenceDatasetOilRate = JSON.parse(response["referenceDatasetOilRate"]);

    const upperData = referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')];
    const mainData = referenceDataset.Value[rawReferenceData.description.indexOf('Reference')];
    const lowerData = referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')];

    const upperDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('upper_thresh')];
    const mainDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('Reference')];
    const lowerDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('lower_thresh')];

    // this.setProductionDataDict({
    // 	date: time,
    // 	gas_rate: gasData,
    // 	nodeid: tasqsListModule.activeTasq?.wellName,
    // 	oil_rate: oilData,
    // 	water_rate: waterData
    // })

  

    if(!isOfflinePreloading) {

      this.setProductionDataDefermentLabeling({
        data: [
          oilData,
          waterData,
          gasData,
          upperData,
          mainData,
          lowerData,
          upperDataOilRate,
          mainDataOilRate,
          lowerDataOilRate,
        ],
        time,
        loaded: true,
      });
    }
  }




  @Action
  async getProductionData(data: any = 14): Promise<void> {
    // daysLength = 100
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName
    let daysLength = 14;
    if(typeof data === 'object') {
      wellName = data.wellName;
      daysLength = data.daysLength;
      isOfflinePreloading = true;
    } else {
      daysLength = data
      this.resetProductionData();
    }
    if (!wellName) {
      return;
    }
   
    // let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName

    let response: any = {};
    if (isOnlineApp) {
      response = await this.queryProductionData(data);
      await localForage.setItem(`getProductionData-${wellName}`, response);
    } else {
      const result = await localForage.getItem(`getProductionData-${wellName}`); 

      if(result){
        response = result;
      }
    }
    var rawReferenceData = response["rawReferenceData"];
    var time = response["time"];
    var gasData = response["gasData"];
    var oilData = response["oilData"];
    var waterData = response["waterData"];
    var rawReferenceDataOilRate = response["rawReferenceDataOilRate"];
    const referenceDataset = JSON.parse(response["referenceDataset"]);
    const referenceDatasetOilRate = JSON.parse(response["referenceDatasetOilRate"]);

    const upperData = referenceDataset.Value[rawReferenceData.description.indexOf('upper_thresh')];
    const mainData = referenceDataset.Value[rawReferenceData.description.indexOf('Reference')];
    const lowerData = referenceDataset.Value[rawReferenceData.description.indexOf('lower_thresh')];

    const upperDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('upper_thresh')];
    const mainDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('Reference')];
    const lowerDataOilRate = referenceDatasetOilRate.Value[rawReferenceDataOilRate.description.indexOf('lower_thresh')];
    // if(upperData && upperData.length){
    //   upperData.unshift(null)
    // }
    // if(upperDataOilRate && upperDataOilRate.length){
    //   upperDataOilRate.unshift(null)
    // }
    // if(mainData && mainData.length){
    //   mainData.unshift(null)
    // }
    // if(lowerData && lowerData.length){
    //   lowerData.unshift(null)
    // }
    // if(mainDataOilRate && mainDataOilRate.length){
    //   mainDataOilRate.unshift(null)
    // }
    // if(lowerDataOilRate && lowerDataOilRate.length){
    //   lowerDataOilRate.unshift(null)
    // }
    

    if(!isOfflinePreloading) {
      this.setProductionDataDict({
        date: time,
        gas_rate: gasData,
        nodeid: wellName,
        oil_rate: oilData,
        water_rate: waterData
      });



      const prodData = {
        dataset:  [
          {
            name: 'Oil Rate',
            data: oilData,
          },
          {
            name: 'Gas Rate',
            data: gasData,
          },
          {
            name: 'Water Rate',
            data: waterData,
          },
          {
            name: 'Target',
            data: mainData,
          }
        ],
        date: time,
      };



      this.setAdminChartProductionData(prodData)


      this.setProductionData({
        data: [
          oilData,
          waterData,
          gasData,
          upperData,
          mainData,
          lowerData,
          upperDataOilRate,
          mainDataOilRate,
          lowerDataOilRate,

        ],
        time,
        loaded: true,
      });
    }
  }

  @Action
  async getWellHistory(data: any = 14 ) {
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    let wellName = tasqsListModule.activeTasq?.wellName || assetsModule.activeTasq?.wellName
    let daysLength = 14;
    if(typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data
    }

    
    if (!wellName) {
      return;
    }

    const fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack  }).toISO().slice(0, -5);
    const toTime = DateTime.utc().minus({days: tasqsListModule.fromNowBack}).toISO().slice(0, -5);

    if (isOnlineApp) {

      const {
        data: {
          list_node_events_v2: {
            dataset,
          },
        },
      } = await cleanDataApolloClient.query({
        query: listNodeEventsV2,
        variables: {
          nodeid: wellName,
          from_time: fromTime,
          to_time: toTime,
          operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      });

      const {
        data: {
          get_procedure_responses_by_node_id: {
            results: unparsedProcedures,
          },
        },
      } = await proceduresApolloClient.query({
        query: getProcedureResponsesByNodeId,
        variables: {
          input: {
            node_id: wellName,
            start_date: fromTime,
            end_date: toTime,
            operator: getConfigEnv('OPERATOR_LOWERCASED')
          },
        },
      });
      const parsedProceduresEvents = unparsedProcedures.map((i) => JSON.parse(i));

      const {
        data: {
          get_well_comment_history: {
            well_history: rawWellHistory,
          },
        },
      } = await workflowApolloClient.query({
        query: getWellCommentHistory,
        variables: {
          node_id: wellName,
          start_date: fromTime,
          end_date: toTime,
          operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      });

      const wellHistory = jsonParse(rawWellHistory?.[0] || null) || [];
      const comments = wellHistory.comments || [];
      let events = [] as any;

      events = dataset ? [...dataset, ...comments, ...parsedProceduresEvents].map((i, idx) => {
        if (i.response_data) {
          const name = i.response_data.user_response ? (i.response_data.user_response || {}).selection || ''
            : (i.response_data.UserResponse || {}).Selection || '';
        const failure = i.response_data.user_response ? i.response_data?.user_response?.selection_details.reason[0] || '' // eslint-disable-line
            : i.response_data?.UserResponse?.SelectionDetails?.Reason[0] || '';
          const yesResponse = name === 'YES';
          return {
            id: idx,
            type: 'Anomaly',
            failure,
            name,
            start: new Date(i.time),
            color: '#ffb01b',
            description: i.comment,
            iconColor: yesResponse ? 'green' : 'red',
            icon: yesResponse ? 'check_circle' : 'cancel',
          };
        }
        if (i.ProcedureID) {
          return {
            id: idx,
            start: new Date(`${i.CreatedTime}Z`),
            type: 'Procedure',
            color: '#8C8FB0',
            description: `Action: ${i.ResponseOption}`,
          };
        }
        const parsed = JSON.parse(i);
      const info = parsed._value || []; // eslint-disable-line
        const setPoint = info[0].Note === 'Setpoint Change';
        return {
          id: idx,
          start: new Date(parsed.time),
          type: setPoint ? 'Setpoint change' : 'Event',
          color: setPoint ? '#BD62FF' : '#0076FF',
          info,
          description: setPoint
          ? `Prior value: ${info[0].PriorValue.toFixed(1) || 'N/A'}, New value: ${info[0].NewValue.toFixed(1) || 'N/A'}` // eslint-disable-line
            : (info || []).map((e) => e.Note).join('. '),
          name: setPoint ? info[0].Description : 'XSPOC alarm',
        };
      }) : [];
      events = events.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());

      if(!isOfflinePreloading) {
        this.setWellHistoryData(events);
      }

      await localForage.setItem(`getWellHistory:${wellName}`, events)
    } else {
      const result = await localForage.getItem(`getWellHistory:${wellName}`);
      if (result) {
        this.setWellHistoryData(result);
      }
    }
  }
}

export default getModule(TasqProductionDataChartModule);
