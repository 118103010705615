import { Component } from 'vue';
import * as config from '@/config';




const getEnv = (key: string): (string|undefined) => process.env[`VUE_APP_${key.toUpperCase()}`];
const isProd = () => getEnv('env') === 'production';
const getConfigEnv = (key: string): any => config.default[key];
const randomInteger = (min: number, max: number): number => Math.floor((Math.random() * ((max - min) + 1)) + min);
const ucWords = (str: string): string => `${str}`.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
const ucfirst = (str:string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
const jsonParse = (json: string): any => JSON.parse(json);
const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

// Vue related
const getComponent = (name: string): Promise<Component> => import(
  /* webpackChunkName: "components/[request]" */ `@/components/${name}.vue`
);
const getView = (name: string): Promise<Component> => import(
  /* webpackChunkName: "views/[request]" */ `@/views/${name}.vue`
);



// Commonly used methods
function addNumberPadding(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function dateDiff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

function getOffset( el ) {
	var _x = 0;
	var _y = 0;
	while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
}

// getPos
function getElementPosition(el, type) {
	for (var lx=0, ly=0;
		el != null;

		lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
	return {x: lx,y: ly};
}

function getElementCenterPosition(el, type) {
	var width = el.getBoundingClientRect().width;
	var height = el.getBoundingClientRect().height;

	var elPosition = getOffset(el)
	var centerX = elPosition.left + width / 2;
	var centerY = elPosition.top + height / 2;


	return {x: elPosition.left + centerX, y: elPosition.top + centerY};
}



export {
  addNumberPadding,
  sleep,
  dateDiff,
  getOffset,
  getElementPosition,
  getElementCenterPosition,
  getEnv,
  isProd,
  getConfigEnv,
  randomInteger,
  ucWords,
  ucfirst,
  jsonParse,
  groupBy,
  getComponent,
  getView,
};
