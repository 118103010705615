import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import * as localForageGlobal from 'localforage';
import { ALL_TASQS_LIST_ITEM, SHOW_ALERT } from '@/lib/constants';
// import { TASQS_LISTS,  } from '@/lib/constants';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';
import assetsModule from '@/store/modules/assetsModule';
import workflowModule from '@/store/modules/workflowModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import proceduresModule from '@/store/modules/proceduresModule';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

async function preloadData() {
  Chart.plugins.unregister(ChartDataLabels);
  if (navigator.onLine) {
    localForage.clear();
    localForageGlobal.clear();

    setTimeout(() => {}, 2000);

    // let vue = new Vue()

    //   await accountModule.getReassignmentList();
    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
    const enablePreloading = !!(workflowModule.user != null && workflowModule.user.role != null && (workflowModule.user.role == 'Operators' || workflowModule.user.role == 'FieldOperator'))
	  // const myUser = await accountModule.user;
    if (enablePreloading) {
    //  vue.$eventBus.$emit(SHOW_ALERT, 'Offline preloading started!');
      proceduresModule.getProceduresStepsJson({});
      tasqsListModule.addProgresPercentage(10);
      tasqsListModule.setTasqListLevel('Pad');
      tasqsListModule.setsaveOffline(true);
      const promises: any[] = [];

      promises.push(await tasqsListModule.getTasqsForOfflineUse(ALL_TASQS_LIST_ITEM));
      promises.push(await tasqsListModule.getTasqListResponses());
      promises.push(await assetsModule.getEnabledWells());
      promises.push(await accountModule.getReassignmentList());
      promises.push(await tasqsListModule.getWaitList());

      Promise.all(promises).then(async () => {
        const result: any = await localForage.getItem('saveOfflineTodayTasqList');
        const counter = (80 / result.length);
        const wellNames: any = [];
        if (result && result.length) {
          accountModule.setDidPreloadData(true);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < result.length; i++) {
            console.log((result[i].wellName));
            if (!wellNames.includes(result[i].wellName)) {
              await preloadingTasqCharts(result[i]);
              // await (tasqProductionDataChartModule.getWellHistory({ daysLength: 5, wellName: result[i].wellName }));
              // await (workflowModule.getWellHistory({ wellName: result[i].wellName }));
              wellNames.push(result[i].wellName);
              localForageGlobal.clear();
            }
            await preloadingTasqFeedback(result[i]);
            console.log(i);
			  let resetCounter = counter;
			  if (counter > 100) {
              resetCounter = 100;
			  }
            tasqsListModule.setProgresPercentage(resetCounter);
            localForageGlobal.clear();
          }
          // tasqsListModule.setActiveTasq('');
        } else {
          tasqsListModule.addProgresPercentage(0);
        }
      }, (err) => {
        console.log(err);
        // error occurred
      });

      tasqsListModule.setsaveOffline(false);
    }
  }
}

async function preloadingTasqCharts(result) {
  try {
    // console.log(result.engineerType)
    if (result.engineerType === 'Setpoint') {
      await setpointV2Module.getSetpointRecV2ForTasq({ wellName: result.wellName, isOfflinePreloading: true });
    }
    await tasqSignalsModule.getSignalsForTasq({ daysLength: 5, wellName: result.wellName });
    await (tasqProductionDataChartModule.getProductionData({ daysLength: 5, wellName: result.wellName }));
    // await (defermentLabelingModule.getChartLabelingData({signalChartTime: 5, wellName: result.wellName}));
  } catch (error) {
    console.log(`Something went wrong on signals offline storage ${result.wellName}`);
  }
}

async function preloadingTasqFeedback(result) {
  try {
    await (workflowModule.getJobResponse({ id: result.id }));
    await (workflowModule.getJobResponseV2({ id: result.id }));
  } catch (error) {
    console.log(`Something went wrong on Feedback offline storage ${result.wellName}`);
  }
}

export {
  preloadData,
};
