import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2 from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,

  name: 'tasqSignalsModule',
  store,
})
class TasqSignalsModule extends VuexModule {
  availableSignals = getConfigEnv('SIGNALS_ARRAY');

  currentSignals: any = [];
  signalDescriptions: any = [];

  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  setSignalDescriptions(descriptions): void {
    this.signalDescriptions = descriptions;
  }

  @Mutation
  addSignal(signal): void {
    const currentSignalsNames = this.currentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentSignals.push(signal);
  }

  @Mutation
  setCurrentSignal(data): void {
    this.currentSignals = data;
  }

  @Action
  async getSignalsForTasq(data: any = 14): Promise<void> {
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    let dataCount = 2000;
    let wellName = tasqsListModule.activeTasq?.wellName != null ? tasqsListModule.activeTasq?.wellName : assetsModule.activeTasq?.wellName;
    let daysLength = 14;
    if(typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data
      this.resetSignals();
    }

    if(daysLength === 175){
      dataCount = 200000
    }
    // if (!tasqsListModule.activeTasq?.wellName && !assetsModule.activeTasq?.wellName) return;
   
    
    if(!wellName) {
      return;
    }
    // console.log('getSignalsForTasq')
    if (isOnlineApp) {
      const {
        data: {
          get_clean_ts_data_node_description_combinations_v2: nodeData,
        },
      } = await cleanDataApolloClient.query({
        query: getNodeDescriptionCombinations,
        variables: {
          operator: getConfigEnv('OPERATOR_PASCALED'),
          nodeid: wellName,
          filter: 'tasq_page',
        },
      });
      const backendSignals = jsonParse(nodeData)?.Description || [];
      let signalsToFetch = backendSignals;
      // if(tasqsListModule.activeTasq?.rtCauseSignal) {
      //   signalsToFetch = [tasqsListModule.activeTasq?.rtCauseSignal].concat(signalsToFetch);
      // }
      // const signalsToFetch = this.availableSignals.filter(
      //   (s) => backendSignals.includes(s.slug),
      await localForage.setItem(`getSignalsForTasq:getNodeDescriptionCombinations-${wellName}`, (signalsToFetch));
      // ).map((s) => s.slug);
      if(!isOfflinePreloading) {
        this.setSignalDescriptions(signalsToFetch);
      }
      const fromTime = DateTime.utc().minus({ days: daysLength + tasqsListModule.fromNowBack  }).toISO().slice(0, -5);
      const toTime = DateTime.utc().minus({days: tasqsListModule.fromNowBack}).toISO().slice(0, -5);
      let offlineSignals: any = [];
  
      signalsToFetch.map(async (signal, idx) => {
        const {
          data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
        } = await cleanDataApolloClient.query({
          query: getCleanTSDataTimeRangeV2,
          variables: {
            nodeid: wellName,
            description: [signal],
            downsample_method: `{"method": "lttb", "limit": ${dataCount}}`,
            from_time: fromTime,
            to_time: toTime,
            operator: getConfigEnv('OPERATOR_PASCALED'),
          },
        });
        const parsedDataset = JSON.parse(dataset);

        const signalLocal = {
          id: idx,
          name: description[0],
          dataset: parsedDataset.Value[0],
          time: parsedDataset.time[0],
          unit: units[0],
        };

        if (!isOfflinePreloading) {
          this.addSignal(signalLocal);
        }
        offlineSignals.push(signalLocal)
        await localForage.setItem(`getSignalsForTasq:getCleanTSDataTimeRangeV2-${wellName}`, offlineSignals);
      });
    } else {
      let result = await localForage.getItem(`getSignalsForTasq:getNodeDescriptionCombinations-${wellName}`);
      if (result) {
        this.setSignalDescriptions(result);
        result = await localForage.getItem(`getSignalsForTasq:getCleanTSDataTimeRangeV2-${wellName}`);
        if (result) {
          this.setCurrentSignal(result);
        }
      }
    }
  }
}

export default getModule(TasqSignalsModule);
