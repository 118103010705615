import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { decodeToken } from '@/lib/auth';
import workflowApolloClient from '@/lib/appsync/workflow';
import axios from 'axios';
import getUserReassignmentList from '@/graphql/workflow/queries/getUserReassignmentList.graphql';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'accountModule',
  store,
})
class AccountModule extends VuexModule {
  reassignmentList: ReassignUser[] = [];

  user: any = {};

  didPreloadData: boolean = false

  allUsersList: any = [];


  @Mutation
  setDidPreloadData(val) {
	  this.didPreloadData = val
  }

  @Mutation
  updateFirstName(newFirstName): void {
    this.user = newFirstName
  }


  @Mutation
  setUserRole(role): void {

    this.user.role = role
	
  }




  @Mutation
  setUser(): void {
    const token = localStorage.getItem('AccessToken');

	var role_list = ["Automation", "Engineers", "Optimizer", "Operators", "Maintenance", "SafetyCritical", "FieldOperator", "Intervention"]
	var role = "Engineers"

    let defaultPage = "Tasqs"
	
    if (token) {
		var decoded_groups = decodeToken(token)['cognito:groups']
	
		for (var x = 0; x < decoded_groups.length; x++) {
			if (role_list.includes(decoded_groups[x])) {
				role = decoded_groups[x]
			}
		}

    if(decodeToken(token)["custom:default_page"]){
      defaultPage =  decodeToken(token)["custom:default_page"]
    }else if(role){
      if(role === 'Engineers'){
         defaultPage = "TasqsKanban"
      }else if(role === 'Mangers'){
        defaultPage = 'Report'
      }
    }

		var teams: any[] = []
		for (var y = 0; y < decodeToken(token)['cognito:groups'].length; y++) {
			if (decodeToken(token)['cognito:groups'][y].includes("Team_")) {
				teams.push(decodeToken(token)['cognito:groups'][y].replace("Team_", ""))
			}
			
		}
      // ts-ignore
      this.user = {
        id: decodeToken(token).sub,
        username: decodeToken(token)['cognito:username'],
        email: decodeToken(token).email,
		    role: role,
        groups: decodeToken(token)['cognito:groups'],
        isAdmin: (decodeToken(token)['cognito:groups'] || []).includes('AdminGroup'),
        phoneNumber: decodeToken(token)["phone_number"],
        firstName: decodeToken(token)["custom:first_name"],
        lastName: decodeToken(token)["custom:last_name"],
        // ts-ignore
        defaultPage: defaultPage,
        team: teams
      };
      // @ts-ignore
      window.Appcues.identify(decodeToken(token).email);
    }
  }

  @Mutation
  setReassignmentList(list): void {
    this.reassignmentList = list;
  }

  @Mutation
  setAllUserstList(list): void {
    this.allUsersList = list;
  }

  @Action
  async login({ email }): Promise<void> {
    const { data: { session, tokens } } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`, {
      type: 'login',
      email,
    });
    if (session) {
      localStorage.setItem('session', session);
      localStorage.setItem('email', email.toLowerCase());
    }
  }

  @Action
  async verify({ code }): Promise<void> {
    const { data: { tokens } } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`, {
      type: 'respond',
      session: localStorage.getItem('session'),
      email: localStorage.getItem('email'),
      code,
    });
    if (tokens) {
      localStorage.removeItem('IdToken');
      localStorage.removeItem('RefreshToken');
      Object.keys(tokens).map((token) => localStorage.setItem(token === 'IdToken' ? 'AccessToken' : token, tokens[token]));
    }
  }

  @Action({
    commit: 'setReassignmentList',
  })
  async getReassignmentList(): Promise<ReassignUser[]> {
    try {
      let items: any = {};
      if (navigator.onLine) {
        const {
          data:
          {
            get_user_reassignment_list: {
              UserDict: userDict,
            },
          },
        } = await workflowApolloClient.query({
          query: getUserReassignmentList,
          variables: {
		  Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          fetchPolicy: 'network-only'
        });

        items = jsonParse(userDict) || {};
        await localForage.setItem('getReassignmentList', items);
      } else {
        const result = await localForage.getItem('getReassignmentList');
        if(result) {
          items = result;
        }
      }

      const userResults = Object.values(items)
      .map((u, idx) => ({
        // @ts-ignore
        ...u,
        email: Object.keys(items)[idx],
        name: `${u.name_dict?.first_name || Object.keys(items)[idx]} ${u.name_dict?.last_name || ''}`,

        totalAssignments: u.TotalCount,
      }))
      this.setAllUserstList(userResults)
      return userResults.filter((u) => u.active);
    } catch (e) {
      console.log(e.message)
      throw new Error(e);
    }
  }
}

export default getModule(AccountModule);
