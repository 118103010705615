import { ucfirst } from '@/utils/helpers';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SCHEDULED_JOB_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_STATE_CHANGE_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
} from '@/lib/constants';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { DateTime } from 'luxon';

const getStepOneCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection;
const getStepTwoCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason.length;
const getStepThreeCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action;
const getStepFourCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action
  && tasq?.responseData?.user_response?.corrected_state_change_date;

const getStepLabel = (tasq: TasqJob) => {
  let label = 'No action';
  if (tasq.stepOneCompleted) {
    label = 'Validated';
  }
  if (tasq.stepTwoCompleted) {
    label = 'Labeled';
  }
  if (tasq.stepThreeCompleted) {
    label = 'Actioned';
  }
  if (tasq.stepFourCompleted) {
    label = 'Resolved';
  }
  return label;
};







const getDateAgoText = (tasq: TasqJob) => {
  let date = '';
  // @ts-ignore
  const waiting = tasq?.snoozed?.wait_until_reason && tasq?.snoozed?.until_date;
  let time = tasq?.assignmentTime;
  if (waiting) {
    // @ts-ignore
    time = tasq?.snoozed.until_date;
  }
  if (tasq?.predictionType === TASQ_OFF_TARGET_TYPE) {
    time = tasq?.offTargetStartDate;
  }
  if (tasq?.predictionType === TASQ_STATE_CHANGE_TYPE
    || tasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
  ) {
    time = tasq?.stateChangeStart;
  }
  if (!time) {
    return date;
  }
  // @ts-ignore
  const timeVal = DateTime.fromISO(time);
  let value = DateTime.local().diff(timeVal, 'days').toObject()?.days?.toFixed();
  if (waiting) {
    value = timeVal.diff(DateTime.local(), 'days').days.toFixed();
  }
  if (value && value !== '0') {
    date = value === '1'
      ? `${value} day` : `${value} days`;
    if (waiting) {
      date = `in ${date}`;
    }
  }
  if (value === '0') {
    date = 'Today';
  }
  return {
    text: date,
    value: Number(value),
  };
};

const getBgColorClass = (tasq: TasqJobResponse) => {
  // if (tasq.isWorkflowGen) return 'bg-color--pink';
  switch (tasq.PredictionType) {
    case TASQ_PREDICTION_TYPE:
    case TASQ_FAILURE_PREDICTION_TYPE:
      return 'bg-tasqGreen';
    case TASQ_FAILURE_TYPE:
    case TASQ_OFF_TARGET_RT_TYPE:
      return 'bg-tasqRed';
    case TASQ_DOWN_TYPE:
    case TASQ_OFF_TARGET_TYPE:
      return 'bg-tasqOrange';
    case TASQ_LABEL_TYPE:
      return 'bg-tasqBlueLight';
    case TASQ_PRODUCING_TYPE:
    case TASQ_PM_TYPE:
    case TASQ_GAUGE_TYPE:
    case TASQ_REGULATORY_TYPE:
    case TASQ_SITE_CHECK_TYPE:
    case TASQ_NO_COMMS_TYPE:
    case TASQ_WELL_TEST_TYPE:
    case TASQ_SCHEDULED_JOB:
      return 'bg-tasqGrayDark';
	case TASQ_WORKFLOW_TYPE:
	  return 'bg-tasqViolet';
    case TASQ_SETPOINT_TYPE:
      return 'bg-tasqBlue';
    default:
      return 'bg-tasqYellowDark';
  }
};

const getOverriddenTasqName = (tasq: TasqJobResponse) => {
  switch (tasq.PredictionType) {
    case TASQ_PROLONGED_ANOMALY_TYPE:
    case TASQ_STATE_CHANGE_TYPE:
      return 'Anomaly';
	case TASQ_OFF_TARGET_RT_TYPE:
		return 'Real-Time';
    case TASQ_WELL_TEST_TYPE:
	  return "Well test"
    case 'Off-Target RT':
    return 'REAL-TIME';
    default:
		// @ts-ignore
      return tasq.ID ? "Scheduled Job" : ucfirst(tasq.PredictionType || '');
  }
};

const getOverriddenManualTasqName = (tasq: TasqJobResponse) => {
  let jobType =  [
	{
		"key": "Contractor Jobs",
		"value": "Contractor Jobs",
		"id": 0
	},
	{
		"key": "Drain Pots and Drips",
		"value": "Drain Pots and Drips",
		"id": 1
	},
	{
		"key": "Drain Tank Bottoms",
		"value": "Drain Tank Bottoms",
		"id": 2
	},
	{
		"key": "Dump Checks",
		"value": "Dump Checks",
		"id": 3
	},
	{
		"key": "Equipment Inspection",
		"value": "Equipment Inspection",
		"id": 4
	},
	{
		"key": "Facility Walkdown",
		"value": "Facility Walkdown",
		"id": 5
	},
	{
		"key": "Facility Winterization",
		"value": "Facility Winterization",
		"id": 6
	},
	{
		"key": "Gauge",
		"value": "Gauge",
		"id": 7
	},
	{
		"key": "HSE",
		"value": "HSE",
		"id": 8
	},
	{
		"key": "Hot Oiling",
		"value": "Hot Oiling",
		"id": 9
	},
	{
		"key": "Install Artificial Lift",
		"value": "Install Artificial Lift",
		"id": 10
	},
	{
		"key": "Install Compressor",
		"value": "Install Compressor",
		"id": 11
	},
	{
		"key": "LOTO",
		"value": "LOTO",
		"id": 12
	},
	{
		"key": "Preventative Maintenance",
		"value": "Preventative Maintenance",
		"id": 13
	},
	{
		"key": "Regain Supply Gas",
		"value": "Regain Supply Gas",
		"id": 14
	},
	{
		"key": "Regulatory",
		"value": "Regulatory",
		"id": 15
	},
	{
		"key": "Replace Bad Battery",
		"value": "Replace Bad Battery",
		"id": 16
	},
	{
		"key": "Schedule Work",
		"value": "Schedule Work",
		"id": 17
	},
	{
		"key": "Site Check",
		"value": "Site Check",
		"id": 18
	},
	{
		"key": "Thaw Equipment",
		"value": "Thaw Equipment",
		"id": 19
	},
	{
		"key": "Unknown Well Issue",
		"value": "Unknown Well Issue",
		"id": 20
	}
]
  let findJob = jobType.find(job => job.value === tasq.PredictionType);
  if(findJob){
    if(findJob.key === 'Preventive Maintenance'){
      return 'PM'
    }
    return findJob.key
  }
  return tasq.PredictionType
};

const mapDefaultTasq = (t, userEmail = ''): TasqJob => {
  // const workflowDetails = t.WorkflowCondition
  //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null;




	var wellName = t.wellName != null ? t.wellName : t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID
	if (t.wellName != null) {
		wellName = t.wellName 

	} else {

		wellName = t.NodeID
		if (t.NodeID.wellName != null) {
			wellName = t.NodeID.wellName

		}
	}

	var initialUserName = t.Assignee != null ? t.Assignee.initial_assignment : '' // eslint-disable-line
	var assignee = t.Assignee != null ? t.Assignee : t.Username // eslint-disable-line
	var username = t.Username != null ? t.Username.toLowerCase() : t.Username
	if (t.ID != null) {
		for (var x = 0; x < t.Columns.length; x++) {
			if (t.Columns[x]["ColumnType"] == "USER" && t.Columns[x]["CoreField"]) {
				username = t.Columns[x]["Response"]
			}
		}

	}


	const tasq = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
	scheduledJobID: t.ID != null ? t.ID : null,
    predictionType: t.PredictionType != null ? t.PredictionType : t.ID != null ? 'Scheduled Job' : 'Producing',
    engineerType: t.PredictionType != null ? ucfirst(t.PredictionType === 'Off-Target RT' ? 'Real-Time' : t.PredictionType || '') : t.ID != null ? 'Scheduled Job' : 'Producing',
    wellName: wellName,
    date: t?.StateChangeDate,
    stateChangeStart: t.StateChangeDate,
    stateChangeEnd: new Date(), // t.StateChangeDateEnd,
    predictionsList: t.Predictions,
    time: t.time,
    lastResponseTime: t.LastResponseTime ? t.LastResponseTime : [],
    // states: t.States,
    stateChangeDate: t.StateChangeDate,
	scheduledJobStartDate: t["Start Date"] != null ? new Date(t["Start Date"]) : null,
	scheduledJobDueDate: t["Due Date"] != null ? new Date(t["Due Date"]) : null,
    responseData: t.ResponseData,
    viewed: t.Viewed,
    snoozed: t.Snoozed,
    rtCauseSignal: (t?.Payload != null && t?.Payload.Signal != null) ? t?.Payload.Signal : null,
    isViewed: (t.Viewed || []).includes(userEmail),
    assignee: assignee,
    isLocked: t.locked && (t.locked || {}).Status,
    isRepetitive: t.repetitive,
    isSnoozed: t.snoozed,
    isRejected: t.rejected,
    noComms: t.PredictionType === TASQ_NO_COMMS_TYPE,
    isWorkflowGen: t.WorkflowDetailsID || false,
    workflowTasqId: t.WorkflowTaskID,
    workflowDetailsID: null,
    // workflowDetails: t.WorkflowCondition
    //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null,
    // workflowResultDict: (t?.workflow_condition_results_dict || [])[0], // eslint-disable-line
    username: username, // eslint-disable-line
    reassignedUserName: t.Assignee != null && t.Assignee.reassignment != null ? t.Assignee.reassignment.new_assignee || '' : '', // eslint-disable-line
	initialUserName: initialUserName, // eslint-disable-line
    isManuallyAdded: t.ManuallyAssigned,
    offTargetStartDate: t.OffTargetStartDate,
    // prolongedResponseData: t.ProlongedResponseData,
    isWatched: t?.Watchlist?.includes(userEmail),
    assignmentTime: t?.AssignmentTime,
    completed: t.Closed && t.Closed == "True",
	completedForToday: t.CompletedForToday != null ? (new Date(t.CompletedForToday + 'Z').setHours(0,0,0,0) == (new Date()).setHours(0,0,0,0)) : false,
    defermentValue: t?.DefermentValue,
    defermentType: (t?.Payload != null && t?.Payload.DefermentType != null) ? t?.Payload.DefermentType : "gas_rate",
    defermentUnit: (t?.Payload != null && t?.Payload.Units != null) ? t?.Payload.Units : "mcf",
	outcome: (t?.Payload != null && t?.Payload.Outcome != null) ? t?.Payload.Outcome : null,
    comments: t.comments,
    wells: [],
    bgColorClass:  t.ManuallyAssigned ? 'bg-tasqGrayDark' : getBgColorClass(t),
    overriddenName: t.ManuallyAssigned ? getOverriddenManualTasqName(t): getOverriddenTasqName(t) ,
    stepOneCompleted: false,
    stepTwoCompleted: false,
    stepThreeCompleted: false,
    stepFourCompleted: false,
    respondedToday: false,
    respondedOneDayAgo: false,
    respondedTwoDaysAgo: false,
    respondedThreeDaysAgo: false,
    respondedFourDaysAgo: false,
    level: t.Level != null ? t.Level : t.level != null ? t.level : 'WELL',
    stepLabel: '',
	scheduledJobStatus: t.Status != null ? t.Status.Status : null,
	scheduledJobColumns: t.Columns !=  null ? t.Columns : null,
    dateAgoText: '',
    wellTestData: null
  };

  if (t.WorkflowDetailsID != null) {
    tasq.workflowDetailsID = t.WorkflowDetailsID
  }
  if (t.Payload != null && t.PredictionType != null && (t.PredictionType.toLowerCase() == "anomaly" || t.PredictionType.toLowerCase() == "state change")) {
    tasq.stateChangeStart = t.Payload.Record.StateChangeDate
    tasq.stateChangeEnd = new Date() // t.Payload.Record.StateChangeDateEnd
    tasq.stateChangeDate = t.Payload.Record.StateChangeDate
    tasq.date = t.Payload.Record.StateChangeDate
  } else if (t.PredictionType != null && "off-target" == t.PredictionType.toLowerCase() && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(" ", "T")
    tasq.defermentValue = t.Payload.MCF
  } else if (t.PredictionType != null && "off-target rt" == t.PredictionType.toLowerCase() && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(" ", "T")
    const updatedDate = DateTime.fromISO(t.Payload.OffTargetStartDate, {zone: 'America/Denver'})
	// @ts-ignore
    tasq.rtStartDate = updatedDate
    tasq.defermentValue = t.Payload.MCF
  }

  if (t.Wells != null) {
	  tasq.wells = t.Wells
  } else if (t.wells != null) {
    tasq.wells = t.wells
  }
  //   if (t.Level != null) {
  // 	  tasq.level = t.Level
  //   }




  var todaysDate = new Date();
  var oneDayAgo = new Date().setDate(new Date().getDate()-1);
  var twoDayAgo = new Date().setDate(new Date().getDate()-2);
  var threeDayAgo = new Date().setDate(new Date().getDate()-3);
  var fourDayAgo = new Date().setDate(new Date().getDate()-4);
  for (var x = 0; x < tasq.lastResponseTime.length; x++) {
    var movedOutside = tasq.lastResponseTime[x] + ".000Z"
    var lastResponseLocalTime = new Date(movedOutside)
    // call setHours to take the time out of the comparison
    if(lastResponseLocalTime.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
      // Date equals today's date
      tasq.respondedToday = true
    }
    if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(oneDayAgo).setHours(0,0,0,0)) {
      // Date equals today's date
      tasq.respondedOneDayAgo = true
    }
    if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(twoDayAgo).setHours(0,0,0,0)) {
      // Date equals today's date
      tasq.respondedTwoDaysAgo = true
    }
    if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(threeDayAgo).setHours(0,0,0,0)) {
      // Date equals today's date
      tasq.respondedThreeDaysAgo = true
    }
    if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(fourDayAgo).setHours(0,0,0,0)) {
      // Date equals today's date
      tasq.respondedFourDaysAgo = true
    }
  }

  // @ts-ignore
  tasq.stepOneCompleted = getStepOneCompleted(tasq);
  // @ts-ignore
  tasq.stepTwoCompleted = getStepTwoCompleted(tasq);
  // @ts-ignore
  tasq.stepThreeCompleted = getStepThreeCompleted(tasq);
  // @ts-ignore
  tasq.stepFourCompleted = getStepFourCompleted(tasq);
  // @ts-ignore
  tasq.stepLabel = getStepLabel(tasq);
  // @ts-ignore
  tasq.dateAgoText = getDateAgoText(tasq).text;
  // @ts-ignore
  tasq.dateAgoValue = getDateAgoText(tasq).value;
  // @ts-ignore


  if (t.TaskID != null && t.ClusterID != null && t.GanttChartID != null) {
	  // @ts-ignore
    tasq.wellTestData = {
      // @ts-ignore
      taskID: t.TaskID,
      // @ts-ignore
      clusterID: t.ClusterID,
      // @ts-ignore
      ganttChartID: t.GanttChartID,
    }
  }

  // @ts-ignore
  return tasq;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultTasq,
};
