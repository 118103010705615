import Vue from 'vue';
import installVue from '@/lib/installVue';
import installVueTailwind from '@/lib/installVueTailwind';
import '@/lib/directives/closable';
import App from './App.vue';
import router from './router';
import store from './store';
Vue.config.ignoredElements = [/^cq-.*$/, /^stx-.*$/]

Vue.config.productionTip = false;
Vue.component('template-placeholder', {
	render: function(createElement) {
		return createElement('template', this.$slots.default)
	}
})
// Init Plugins
installVue(Vue);
installVueTailwind(Vue);
new Vue({
  router,
  store,
  render: (h) => h(App),
  watch: {
    '$route': () => {
      // @ts-ignore
      window.Appcues.page()
    }
  }
}).$mount('#app');
