import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import setpointV2ApolloClient from '@/lib/appsync/setpointv2';
import getSetpointRecV2 from '@/graphql/setpoints/queries/getSetpointRecV2.graphql';
import isOnline from 'is-online';
import localforage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'setpointV2Module',
  store,
})
class SetpointV2Module extends VuexModule {
  tasqSetpointData: any = {};
  tasqListPressureData: any = {};
  tasqMCFData: any = {};
  unitType: any = "-";
  uplift: any = "-";


  @Mutation
  setUplift(data): void {
    this.uplift = data;
  }


  @Mutation
  setUnitType(data): void {
    this.unitType = data;
  }

  @Mutation
  setTasqSetpointData(data): void {
    this.tasqSetpointData = data;
  }

  @Mutation
  setTasqListPressureData(data): void {
    this.tasqListPressureData = data;
  }

  @Mutation
  setTasqMCFData(data): void {
    this.tasqMCFData = data;
  }




  @Action
  async getSetpointRecV2ForTasq({
    wellName,
    isOfflinePreloading= false
  }) {
    let result: any = null;
    try {

      if (await isOnline()) {
        const { data: { get_setpoint_rec_v3: { data } } } = await setpointV2ApolloClient.query({
          query: getSetpointRecV2,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED').charAt(0).toUpperCase() + getConfigEnv('OPERATOR_LOWERCASED').slice(1),
            nodeid: wellName,
          },
        });

        await localforage.setItem(`getSetpointRecV2ForTasq-${wellName}`, data)

        result = data;
      } else {
        result = await localforage.getItem(`getSetpointRecV2ForTasq-${wellName}`)
      }
      

      if(!isOfflinePreloading && result) {
	  this.setTasqSetpointData(JSON.parse(JSON.parse(result))["setpoints"])
	  this.setTasqListPressureData(JSON.parse(JSON.parse(result)).lift_pressure)
	  // bbl
	  if (JSON.parse(JSON.parse(result)).bbl != null) {
          this.setUplift(JSON.parse(JSON.parse(result)).uplift_bbl)
          this.setUnitType("bbl")
          this.setTasqMCFData(JSON.parse(JSON.parse(result)).bbl)
	  } else {
          this.setUplift(JSON.parse(JSON.parse(result)).uplift_mcf)
          this.setUnitType("mcf")
          this.setTasqMCFData(JSON.parse(JSON.parse(result)).mcf);
        }
	  }
	  

      //   const parsedDataset = jsonParse(dataset);
      //   const { plot } = parsedDataset[0];
      //   const setpointData = {} as any;
      //   setpointData.labels = plot.dataset['Tubing Pressure'];
      //   setpointData.casing = plot.dataset['Casing Pressure'];
      //   setpointData.static = plot.dataset['Static Pressure'];
      //   setpointData.loadFactor = plot.dataset['Load Factor'];
      //   [, setpointData.mainData] = plot.dataset.Value;
      //   [,, setpointData.lowerData] = plot.dataset.Value;
      //   [setpointData.upperData] = plot.dataset.Value;
      //   setpointData.currentPoint = plot.dataset.Current;
      //   setpointData.recommendedPoint = plot.dataset.Recommend;
      //   setpointData.uplift = (plot.dataset.perc_uplift * 100).toFixed();

    //   this.setSetpointDataForTasq(setpointData);
    } catch (e) {
      console.error(e);
    }
  }
}

export default getModule(SetpointV2Module);
