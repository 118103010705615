








import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import * as localForageGlobal from 'localforage';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import assetsModule from './store/modules/assetsModule';
import workflowModule from './store/modules/workflowModule';
import setpointV2Module from './store/modules/setpointV2Module';
import {
    preloadData,
} from '@/utils/preloadOfflineData'

@Component({
  components: {},
})
export default class App extends Vue {


  async beforeCreate() {
    // if (isMobile && !isProd()) {
    // window.location.href = getConfigEnv('MOBILE_SITE');
    // }
  
  }

  async created() {
    if (!this.$route.meta.skipAuth && getAccessToken()) {
      if (getAccessToken() && isTokenExpired()) {
        await refreshAccessToken();
      }
      await accountModule.getReassignmentList();
    }
  }

  async mounted() {
	if (!accountModule.didPreloadData) {
		preloadData()
	}
    // Chart.plugins.unregister(ChartDataLabels);
    // if (await isOnline()) {
    //   localForage.clear();
    //   localForageGlobal.clear();
      
     
    //   if(window.location.pathname !== '/') {
    //     // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    //   }

	  
    //   setTimeout(()=> {}, 2000)
	  
	//   await accountModule.getReassignmentList();
    //   if(accountModule.user.groups.includes('Operators') || accountModule.user.groups.includes('FieldOperator') || accountModule.user.groups.includes('Field Operator')) {
    //     accountModule.setDidPreloadData(true)
	// 	tasqsListModule.addProgresPercentage(10)
    //       tasqsListModule.setTasqListLevel('Pad')
    //      tasqsListModule.setsaveOffline(true)
    //     const promises: any[] = [];
    //     promises.push(await tasqsListModule.getTasqsForOfflineUse(ALL_TASQS_LIST_ITEM));
    //     promises.push(await tasqsListModule.getTasqListResponses());
    //     promises.push(await assetsModule.getEnabledWells());
    //     promises.push(await accountModule.getReassignmentList())
    //     promises.push(await tasqsListModule.getWaitList());
     

    //     Promise.all(promises).then(async () => {
    //       const result: any = await localForage.getItem('saveOfflineTodayTasqList');
    //       const counter = (80 / result.length)
    //       const wellNames: any = [];
    //       if (result && result.length) {
    //       // eslint-disable-next-line no-plusplus
    //         for (let i = 0; i < result.length; i++) {
    //           if(!wellNames.includes(result[i].wellName)) {
    //             await this.preloadingTasqCharts(result[i])
    //             // await (tasqProductionDataChartModule.getWellHistory({ daysLength: 5, wellName: result[i].wellName }));
    //             // await (workflowModule.getWellHistory({ wellName: result[i].wellName }));
    //             wellNames.push(result[i].wellName)
    //             localForageGlobal.clear();
    //           }

    //           await this.preloadingTasqFeedback(result[i])
    //           console.log(i)
    //           tasqsListModule.setProgresPercentage(counter);
    //           localForageGlobal.clear();
    //         }
    //         // tasqsListModule.setActiveTasq('');
    //       } else {
    //         tasqsListModule.addProgresPercentage(0);
    //       }
    //     }, (err) => {
    //       console.log(err);
    //     // error occurred
    //     });

    //     tasqsListModule.setsaveOffline(false)
    //   }
    // }
  }

//   async preloadingTasqCharts(result) {
//     try {
//       console.log(result.engineerType)
//       if(result.engineerType === 'Setpoint') {
//         await setpointV2Module.getSetpointRecV2ForTasq({ wellName: result.wellName, isOfflinePreloading: true })
//       }
//       await tasqSignalsModule.getSignalsForTasq({daysLength: 5, wellName: result.wellName});
//       await (tasqProductionDataChartModule.getProductionData({daysLength: 5, wellName: result.wellName}));
//       // await (defermentLabelingModule.getChartLabelingData({signalChartTime: 5, wellName: result.wellName}));    
//     } catch (error) {
//       console.log('Something went worong on signals offline storage ' + result.wellName);
//     }

//   }

//   async preloadingTasqFeedback(result) {
//     try {
//       await (workflowModule.getJobResponse({id: result.id}));
        
//     } catch (error) {
//       console.log('Something went wrong on Feedback offline storage '+ result.wellName);
//     }

//   }
}
